import React from "react";
import InlineImages from "./InlineImages";
import {Link} from "react-router-dom";

const ProductLine = ({item}) => {
    //console.log(item);
    return <div className="item productLine" key={item.index}>
        <Link to={{ pathname: item.href }} target="_blank" >

        <span className="barcode"> {item.barcode}</span>: <span className="prodName"> {item.name}</span>
        </Link>
        <InlineImages imageIds={item.images}/>
    </div>

}

export default ProductLine;