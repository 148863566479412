import React from 'react';
import logoImg from "../assets/img/pontoz.png";
import Footer from "../structure/Footer";

const Home = () =>
    <section >
        <h1>Üdvözli a pontoz.hu</h1>
        <img src={logoImg} alt="logo"/>
        <Footer/>
    </section>

export default Home;