import React, { Component } from 'react'

const setInitialState = (settings) => {
    const { itemHeight, amount, tolerance, minIndex, maxIndex, startIndex } = settings
    const viewportHeight = amount * itemHeight
    const totalHeight = (maxIndex - minIndex + 1) * itemHeight
    const toleranceHeight = tolerance * itemHeight
    const bufferHeight = viewportHeight + 2 * toleranceHeight
    const bufferedItems = amount + 2 * tolerance
    const itemsAbove = startIndex - tolerance - minIndex
    const topPaddingHeight = itemsAbove * itemHeight
    const bottomPaddingHeight = totalHeight - topPaddingHeight
    const initialPosition = topPaddingHeight + toleranceHeight
    return {
        settings,
        viewportHeight,
        totalHeight,
        toleranceHeight,
        bufferHeight,
        bufferedItems,
        topPaddingHeight,
        bottomPaddingHeight,
        initialPosition,
        data: []
    }
}

class Scroller extends Component {
    constructor(props) {
        super(props)
        this.state = setInitialState(props.settings)
        this.viewportElement = React.createRef()
    }

    componentDidMount() {
        this.viewportElement.current.scrollTop = this.state.initialPosition
        if (!this.state.initialPosition) {
            this.runScroller({ target: { scrollTop: 0 } })
        }
    }

    runScroller = ({ target: { scrollTop } }) => {
        const { totalHeight, toleranceHeight, bufferedItems, settings: { itemHeight, minIndex }} = this.state
        const index = minIndex + Math.floor((scrollTop - toleranceHeight) / itemHeight)
        const data = this.props.get(index, bufferedItems)
        const topPaddingHeight = Math.max((index - minIndex) * itemHeight, 0)
        const bottomPaddingHeight = Math.max(totalHeight - topPaddingHeight - data.length * itemHeight, 0)

        this.setState({
            topPaddingHeight,
            bottomPaddingHeight,
            data
        })
    }

    render() {
        const { viewportHeight, topPaddingHeight, bottomPaddingHeight, data } = this.state
        return (
            <div
                className="viewport"
                ref={this.viewportElement}
                onScroll={this.runScroller}
                style={{ height: viewportHeight }}
            >
                <div style={{ height: topPaddingHeight }}></div>
                {
                    data.map(this.props.row)
                }
                <div style={{ height: bottomPaddingHeight }}></div>
            </div>
        )
    }
}

export default Scroller