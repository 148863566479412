import React, {useEffect, useState} from "react";
import Footer from "../structure/Footer";
import axios from 'axios';
import './ProductList.scss';
import VirtualScroller from "../utils/VirtualScroller";
import ProductLine from "../structure/ProductLine";




const ProductList = () => {
    const [records, setRecords] = useState([]);
    const SETTINGS = {
        itemHeight: 20,
        amount: 30,
        tolerance: 5,
        minIndex: 0,
        maxIndex: records.length,
        startIndex: 1
    }
    const rowTemplate = (item) => (
        <ProductLine item={item} />
    )
    const getData = (offset, limit) => {
        const data = []
        const start = Math.max(SETTINGS.minIndex, offset)
        const end = Math.min(offset + limit - 1, records.length)
        //console.log(`request [${offset}..${offset + limit - 1}] -> [${start}..${end}] items`)
        if (start <= end) {
            for (let i = start; i < end; i++) {
                data.push({ index: i, name: records[i].name, barcode: records[i].barcode, images: records[i].images, href: "https://app.pontoz.hu/#/product/" + records[i].product_id})
            }
        }
        return data
    }

    useEffect(()=>{
        axios.get("https://backend.pontoz.hu/get_product_list.php").then(({data}) => {
            setRecords(data);
        })
    },[])
    if (records.length > 0) {
    return <section>
        <h1>Minden termék</h1>
        <VirtualScroller className="viewport" get={getData} settings={SETTINGS} row={rowTemplate}/>
        {/*<ul className="productList">*/}
        {/*    {records.map(({name , product_id , barcode}) => (*/}
        {/*        <li className="productLine" key={product_id.toString()}>*/}
        {/*            <span className="barcode">{barcode}</span> <span className="prodName">{name}</span>*/}
        {/*        </li>*/}
        {/*    ))}*/}
        {/*</ul>*/}
        <Footer/>
    </section>;

    } else {
        return <Footer />
    }
}

export default ProductList;