import React from "react";
import './Footer.scss'
import {Link} from "react-router-dom";

const Footer = () => <footer>
    <nav aria-label="main">
        <ul>
            <li>
                <Link to="/">Nyitólap</Link>
            </li>
            <li>
                <Link to="/product_list">Lista</Link>
            </li>
        </ul>
    </nav>
    <p className="white center">&copy; Pontoz.hu <a href="https://xmikrobi.hu/download/pontoz_hu_1.0.0.apk">APK</a></p>
</footer>

export default Footer;
