import React from 'react';
import './App.css';
import Home from "./pages/Home";
import ProductList from "./pages/ProductList";
import {BrowserRouter as Router, Route} from 'react-router-dom';

function App() {
    return (
        <Router>
            <main>
                <Route path="/" exact component={Home} />
                <Route path="/product_list" component={ProductList} />
            </main>
        </Router>
    );
}

export default App;
