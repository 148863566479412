import React from "react";

const InlineImages = ({imageIds}) => {
    //console.log(imageIds);
    if (imageIds) {
        return imageIds.filter(function(id) {
            return !!id;
        }).map(function (id) {
            return <img src={"https://backend.pontoz.hu/show_image.php?id=" + id + "&width=20"} height="20"/>

        })
    } else {
        return null;
    }
}

export default InlineImages;